import { StyleSheet, Text, View,ImageBackground,Image,TouchableOpacity,Linking } from 'react-native'
import React from 'react'
const logoImage="images/logo.png"
const allergyImage="images/allergy4.png"
const lungImage="images/runnynose3.png"
const asthmaImage="images/inhaler4.png"
function Main({ navigation }) {
  const symptomsData=[
    {symptomId:"1",symptomName:"Allergy with Decongestant",symptomCaption:"Powerful allergy symptom and nasal congestion and sinus pressure relief",backgroundImage:allergyImage},
    {symptomId:"2",symptomName:"Sinus & Cold",symptomCaption:"Powerful sinus and cold symptom relief",backgroundImage:lungImage},
    {symptomId:"3",symptomName:"Asthma",symptomCaption:"",backgroundImage:asthmaImage}
  ]
  React.useEffect(()=>{
    document.title="PSE Products"
  },[])
  return (
    <View style={styles.mainContainer}>
        <Text style={styles.logo}><Image source={logoImage} style={styles.header}/></Text>
          <View><Text style={styles.subHeader}>Select a symptom to view a list of products for relief</Text></View>
           { symptomsData.map((symptom)=>{
              return (<TouchableOpacity key={symptom.symptomId} 
                          onPress={()=>navigation.navigate('Brands', {selectedSymptomId:`${symptom.symptomId}`})} 
                          underlayColor="#FFFFFF"> 
                <View style={styles.nav1}>
                  <View style={styles.content}> 
                    <View style={styles.textContent}>
                          <Text style={styles.mainText}>{symptom.symptomName}</Text>
                          <Text style={styles.subText}>{symptom.symptomCaption}</Text>
                    </View> 
                   <View style={styles.imgContent}> 
                           <ImageBackground source={symptom.backgroundImage} style={styles.image}></ImageBackground>
                   </View>
                  </View>
                  </View></TouchableOpacity>)
            }) }
          <View style={styles.footer}>
                  <Text style={styles.termsText} onPress={()=>{Linking.openURL("https://www.meijer.com/terms.html")}}>
                      Terms and Conditions of Use
                  </Text>
                  <Text style={styles.privacyText} onPress={()=>{Linking.openURL("https://www.meijer.com/privacy-policy.html")}}>
                      Privacy Policy
                  </Text>
          </View>  
    </View>)
}
const styles = StyleSheet.create({
    mainContainer:{
        backgroundColor: "#FFFFFF",
        width:"100%",
        height:800,
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
    },
    header: {
      width:125,
      height:50,
      marginTop:29,
      resizeMode:"contain",
    },
    logo: {
          textAlign:"center"
    },
    subHeader: {
        fontSize:24,
        fontWeight:700,
        lineHeight:30,
        fontStyle:"normal",
        color:"#0c60a5",
        marginTop:24,
        width:314,
        height:90,
        marginLeft:49,
        marginRight:35,
        border: "0.1 solid #0c60a5",
        letterSpacing:"0.01em",
      },
    nav1: {
        paddingHorizontal: 15,
        paddingVertical: 10,
        borderRadius: 20,
        backgroundColor: "#0C60A5",
        marginBottom:30,
        width:"100%",
        height:140,
        border: "1px solid #C4C4C4"
      },
      content:{
        width:"100%",
        display:"flex",
        flexFlow:"row wrap",
      },
      textContent:{
        width:"65%",
        justifyContent:"center"
      },
      imgContent:{
        width:"25%",
        marginLeft:"5%",
        marginTop:"4%"
      },
      mainText: {
          color:"#FFFFFF",
          fontSize:26,
          fontWeight:700,
          fontStyle:"normal",
          lineHeight:25,
          letterSpacing:"0.02em",
          width:178,
          height:5
      },
      subText: {
          color:"#FFFFFF",
          fontSize:16,
          fontWeight:450,
          fontStyle:"normal",
          lineHeight:20,
          letterSpacing:"0.01em",
          width:203,
          height:58,
          marginTop:50,
    },
image:{
  width: 85,
  height: 85,
    },
    footer: {
      width:"100%",
      height:40,
      marginLeft:0,
      marginTop:82,
      display: "flex",
      flexDirection:"row",
      backgroundColor: "lightgray",
      alignItems:"center"
  },
  termsText: {
      width:"60%",
      height:18,
      fontStyle:"normal",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 22.5,
      color: "#464852",
      marginLeft:20
  },
  privacyText: {
    width:"40%",
    height:18,
    fontStyle:"normal",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 22.5,
    color: "#464852",
    textAlign:"center"
}
  });
  
export default Main