import { StyleSheet, Text, View,Image,TouchableOpacity,Linking } from 'react-native'
import { LeftOutlined } from '@ant-design/icons'
import React from 'react';
const logoImage="images/logo.png"
const placeholderImage="images/placeholder.png"
const defaultImage="images/categoriesdefaultimage.png"
function Products({route,navigation}) {
  const {selectedSymptomId,selectedBrandId,selectedBrandName }=route.params
  const [ buttonStyleChange,setButtonStyleChange ] = React.useState(true)
  const [ twelveButtonStyleChange,setTwelveButtonStyleChange ] = React.useState(true)
  const [ twentyFourButtonStyleChange,setTwentyFourButtonStyleChange ] = React.useState(true)
  const [ visibleTwelve,setVisibleTwelve ] = React.useState(true)
  const [ visibleTwentyFour, setVisibleTwentyFour ] = React.useState(true)
  const [visibleNormal,setVisibleNormal ] = React.useState(true)
  const [ changeContainerView,setChangeContainerView ] = React.useState(true)
  const [ medications,setMedications ] =React.useState([])
  const loadData=React.useCallback(()=>{
    fetch(`https://www.meijercongestionrelief.com/medications/${selectedSymptomId}/${selectedBrandId}`,{
      method: "GET",
      headers: {"Content-Type": "application/json;charset=utf-8"}
    })
    .then((res) => res.json())
    .then((medications)=>setMedications(medications))
    // eslint-disable-next-line
  },[])
  React.useEffect(()=>{
    loadData() 
  },[loadData])
  const twelveHoursProductsData=medications.filter((medication)=>{return medication.category === "12 HR" && medication.frontPicture!==""})
  const normalProductsData=medications.filter((medication)=>{return medication.category === "" && medication.frontPicture!==""})
  const twentyFourHoursProductsData=medications.filter((medication)=>{return medication.category === "24 HR" && medication.frontPicture!==""})
  function changeButtonStyle(){
        setChangeContainerView(true)
        setButtonStyleChange(true)
        setTwelveButtonStyleChange(true)
        setTwentyFourButtonStyleChange(true)
        setVisibleNormal(true)
        if (visibleTwelve === false)
          setVisibleTwelve(!visibleTwelve)
        else if(visibleTwentyFour === false)
          setVisibleTwentyFour(!visibleTwentyFour)
        else{
              setVisibleTwelve(visibleTwelve)
              setVisibleTwentyFour(visibleTwentyFour)
            }
  }
  function changeTwelveButtonStyle(){
        setChangeContainerView(false)
        setTwelveButtonStyleChange(false)
        setButtonStyleChange(false)
        setTwentyFourButtonStyleChange(true)
        setVisibleNormal(false)
        if (visibleTwelve === true){
             setVisibleTwelve(visibleTwelve)
             setVisibleTwentyFour(!visibleTwelve)
        }
        else{
          setVisibleTwelve(!visibleTwelve)
          setVisibleTwentyFour(visibleTwelve)
        } 
}
function changeTwentyFourButtonStyle(){
  setChangeContainerView(false)
        setTwentyFourButtonStyleChange(false)
        setButtonStyleChange(false)
        setTwelveButtonStyleChange(true)
        if (visibleTwentyFour === true){
          setVisibleTwelve(!visibleTwentyFour)
          setVisibleTwentyFour(visibleTwentyFour)
     }
     else{
      setVisibleTwelve(visibleTwentyFour)
      setVisibleTwentyFour(!visibleTwentyFour)
     } 
}
  return (
    <View style={styles.mainContainer}>
        <TouchableOpacity onPress={() => navigation.navigate('Brands',{selectedSymptomId:selectedSymptomId})}
                  underlayColor="#0c60a5" style={styles.headerArrow}>
                  <Text style={styles.arrowText}><LeftOutlined /></Text>
        </TouchableOpacity>    
        <TouchableOpacity onPress={() => navigation.navigate('Main')} underlayColor="#FFFFFF">
                <View style={styles.logo}><Image source={logoImage} style={styles.headerText}/></View>
        </TouchableOpacity> 
       <View style={styles.subHeader}>
          <Text style={styles.brandNameText}>{selectedBrandName}</Text>  
        </View>
       {Number(selectedBrandId) === 1 &&
              <View style={styles.caption}>
                <Text style={styles.captionText}>Fast relief of your worst symptoms so you can breathe better</Text>
              </View>}
        {(Object.keys(twelveHoursProductsData).length>0 && Object.keys(twentyFourHoursProductsData).length>0) && 
        <View style={styles.filterButtonsContainer}>
           <TouchableOpacity onPress={changeButtonStyle}>
               <View style={buttonStyleChange?styles.showAllButton:styles.hoursButton}>
                  <Text style={buttonStyleChange?styles.showAllButtonText:styles.hoursButtonText}>Show All</Text>
               </View>
           </TouchableOpacity>
            <TouchableOpacity onPress={changeTwelveButtonStyle}>
              <View style={twelveButtonStyleChange?styles.hoursButton:styles.showAllButton}>
                  <Text style={twelveButtonStyleChange?styles.hoursButtonText:styles.showAllButtonText}>12 Hours</Text>
              </View>
           </TouchableOpacity>
          <TouchableOpacity onPress={changeTwentyFourButtonStyle}>
              <View style={twentyFourButtonStyleChange?styles.hoursButton:styles.showAllButton} >
                <Text style={twentyFourButtonStyleChange?styles.hoursButtonText:styles.showAllButtonText}>24 Hours</Text>
              </View>
           </TouchableOpacity>
        </View>}
        {(Object.keys(medications).length>0 && Object.keys(twelveHoursProductsData).length === 0 && Object.keys(normalProductsData).length === 0
                        && Object.keys(twentyFourHoursProductsData).length === 0) && <View style={styles.captionText}> No Products </View> }
      <View style={((Object.keys(twelveHoursProductsData).length>0 || Object.keys(normalProductsData).length>0) 
                        && Object.keys(twentyFourHoursProductsData).length>0)?
                          (changeContainerView?styles.container:styles.colContainer):styles.colContainer}>
           { visibleTwelve && <View style={ ((Object.keys(twelveHoursProductsData).length>0 || Object.keys(normalProductsData).length>0)
                                              && Object.keys(twentyFourHoursProductsData).length>0)?
                                    (changeContainerView?styles.container1:styles.rowContainer1):styles.rowContainer1 }>
              { twelveHoursProductsData.map((product)=>{
                return  <TouchableOpacity onPress={() => navigation.navigate('ProductDetails',
                                                        { selectedSymptomId:selectedSymptomId,
                                                          selectedBrandId:selectedBrandId,
                                                          selectedBrandName: selectedBrandName,
                                                          selectedProductId:`${product.id}`}) } 
                                          key={product.id}
                                          underlayColor="#FFFFFF" >
                              <View style={(Object.keys(twelveHoursProductsData).length>0 && Object.keys(twentyFourHoursProductsData).length>0)?
                                     (changeContainerView?styles.nav1:styles.nav2):styles.nav2} >
                                  <Image source={product.frontPicture!==""?product.frontPicture:placeholderImage} 
                                  style={styles.brandImage} 
                                  defaultSource={defaultImage} />
                                  <Text style={styles.itemName}>{product.name}</Text>
                              </View>
                        </TouchableOpacity> })} 
                             
            </View> }
            { visibleTwentyFour && <View style={ ((Object.keys(twelveHoursProductsData).length>0 || Object.keys(normalProductsData).length>0) 
                                                   && Object.keys(twentyFourHoursProductsData).length>0)?
                                         (changeContainerView?styles.container2:styles.rowContainer2):styles.rowContainer2 }>
              { twentyFourHoursProductsData.map((product)=>{
                return  <TouchableOpacity onPress={() => navigation.navigate('ProductDetails',
                                                        { selectedSymptomId:selectedSymptomId,
                                                          selectedBrandId:selectedBrandId,
                                                          selectedBrandName: selectedBrandName,
                                                          selectedProductId:`${product.id}`}) } 
                                          key={product.id}
                                          underlayColor="#FFFFFF" >
                              <View style={(Object.keys(twelveHoursProductsData).length>0 && Object.keys(twentyFourHoursProductsData).length>0)?
                                    (changeContainerView?styles.nav1:styles.nav2):styles.nav2}>
                                  <Image source={product.frontPicture!==""?product.frontPicture:placeholderImage} 
                                  style={styles.brandImage} 
                                  defaultSource={defaultImage} />
                                  <Text style={styles.itemName}>{product.name}</Text>
                              </View>      
                       </TouchableOpacity> }) } 
              { visibleNormal && normalProductsData.map((product)=>{
                return  <TouchableOpacity onPress={() => navigation.navigate('ProductDetails',
                                                        { selectedSymptomId:selectedSymptomId,
                                                          selectedBrandId:selectedBrandId,
                                                          selectedBrandName: selectedBrandName,
                                                          selectedProductId:`${product.id}`}) } 
                                          key={product.id}
                                          underlayColor="#FFFFFF" >
                              <View style={((Object.keys(normalProductsData).length>0) && Object.keys(twentyFourHoursProductsData).length>0)?
                                     (changeContainerView?styles.nav1:styles.nav2):styles.nav2} >
                                  <Image source={product.frontPicture!==""?product.frontPicture:placeholderImage} 
                                  style={styles.brandImage} 
                                  defaultSource={defaultImage} />
                                  <Text style={styles.itemName}>{product.name}</Text>
                              </View>
                        </TouchableOpacity> }) }          
            </View> }
        </View>
        <View style={styles.footer}>
                  <Text style={styles.termsText} onPress={()=>{Linking.openURL("https://www.meijer.com/terms.html")}}>
                      Terms and Conditions of Use
                  </Text>
                  <Text style={styles.privacyText} onPress={()=>{Linking.openURL("https://www.meijer.com/privacy-policy.html")}}>
                      Privacy Policy
                  </Text>
          </View> 
    </View>)
}
const styles = StyleSheet.create({
  mainContainer:{
    backgroundColor: "#FFFFFF",
     width:"100%",
     height:800,
     display:"flex",
     flexDirection:"column",
     alignItems:"center"
 },
 headerArrow: {
  backgroundColor:"#0c60a5",
  height:45,
  width:45,
  left:30,
  borderRadius:5,
  top:32,
  position:"absolute"
},
arrowText: {
  marginLeft:13,
  marginRight:13,
  marginTop:8,
  color:"#FFFFFF",
  fontSize:22,
},
logo: {
  alignItems:"center",
  marginTop:29,
},
headerText: {
 width:125,
 height:50,
 resizeMode:"contain",
},
    subHeader:{
        display:'flex',
        flexDirection:"row",
        width:"85%",
        marginTop:30,
        marginLeft:30,
        justifyContent:"space-between"
    },
    brandNameText:{
      fontSize: 24,
      fontWeight:700,
      fontStyle:"normal",
      color:"#464852",
      height:27,
      width:200,
    },
    moreInfo:{
        display:'flex',
        flexDirection:"row",
    },
    moreInfoText: {
        fontSize:14,
        fontWeight:500,
        lineHeight:19,
        fontStyle:"normal",
        color:"#0c60a5",
    },
    wwwImage: {
        height:24,
        width:24,
        marginLeft:8,
        resizeMode:"contain"
    },
    caption:{
      display:'flex',
      flexFlow:"row wrap",
      width:"85%",
      height:60,
      marginTop:10,
      marginLeft:30,
      justifyContent:"center"
  },
    captionText: {
      fontSize:20,
      fontWeight:700,
      fontStyle:"normal",
      color:"#0c60a5",
      border: "0.1 solid #0c60a5",
    },
    filterButtonsContainer:{
        display:'flex',
        flexDirection:"row",
        width:"100%",
        marginTop:28,
        justifyContent:"space-evenly"
    },
    showAllButton: {
        width:110,
        height:40,
        backgroundColor:"#0c60a5",
        borderRadius:5
    },
    showAllButtonText: {
       color:"#FFFFFF",
       fontStyle:"normal",
       fontWeight:600,
       fontSize:16,
       lineHeight:19,
       textAlign:"center",
       marginTop:10
    },
    hoursButton: {
        width:110,
        height:40,
        border: "1px solid #C4C4C4",
        borderRadius:5,
        backgroundColor:"#FFFFFF"
    },
    hoursButtonText: {
       color:"#0c60a5",
       fontStyle:"normal",
       fontWeight:600,
       fontSize:16,
       lineHeight:19,
       textAlign:"center",
       marginTop:10
    },
    noProductsText: {
      color:"#0c60a5",
      fontStyle:"normal",
      fontWeight:600,
      fontSize:22,
      lineHeight:19,
      textAlign:"center",
      marginTop:30
   },
    container: {
      display:'flex',
      flexDirection:'row',
      marginTop: 40,
    },
    colContainer: {
      display:'flex',
      flexDirection:'column',
      marginTop: 40,
    },
    container1: {
      display:'flex',
      flexDirection:'column',
      marginLeft:15
    },
    rowContainer1: {
      display:'flex',
      flexFlow:'row wrap'
    },
    container2: {
      display:'flex',
      flexDirection:'column',
      marginLeft:15
    },
    rowContainer2: {
      display:'flex',
      flexFlow:'row wrap'
    },
    nav1: {
        borderRadius: 5,
        paddingHorizontal:"2%",
        marginBottom:25,
        width:160,
        height:180,
        borderWidth:1,
        borderStyle:"solid",
        borderColor:"#C4C4C4",
        alignItems:"center",
    },
    nav2: {
      borderRadius: 5,
      paddingHorizontal:"2%",
      marginBottom:25,
      marginLeft:15,
      width:160,
      height:180,
      borderWidth:1,
      borderStyle:"solid",
      borderColor:"#C4C4C4",
      alignItems:"center",
  },
    itemName:{
      fontSize: 14,
      fontWeight:700,
      fontStyle:"normal",
      color:"#464852",
      lineHeight:18,
      marginTop:8,
      textAlign:"left",
      marginLeft:18
    },
    brandImage: {
        height:80,
        width:120,
        resizeMode:"contain",
        marginTop:22
    },
    footer: {
      width:"100%",
      height:40,
      marginLeft:0,
      marginTop:82,
      display: "flex",
      flexDirection:"row",
      backgroundColor: "lightgray",
      alignItems:"center"
  },
  termsText: {
      width:"60%",
      height:18,
      fontStyle:"normal",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 22.5,
      color: "#464852",
      marginLeft:20
  },
  privacyText: {
    width:"40%",
    height:18,
    fontStyle:"normal",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 22.5,
    color: "#464852",
    textAlign:"center"
}
  });
  
export default Products