import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Main from "./components/Main";
import Brands from './components/Brands'
import Products from './components/Products'
import ProductDetails from './components/ProductDetails'
const Stack = createNativeStackNavigator();
const linking={prefixes:["http://192.168.1.100:3000","http://3.145.200.223:8080","https://3.145.200.223:8080","http://18.118.222.37:8080","http://18.221.80.213:8080","https://18.118.222.37:8080","https://18.221.80.213:8080"],
  config:{
    screens:{
      initialRouteName:"/",
      Main:"/",
      Brands:"/Brands/:selectedSymptomId",
      Products:"/Products/:selectedSymptomId/:selectedBrandId/:selectedBrandName",
      ProductDetails:"/ProductDetails/:selectedSymptomId/:selectedBrandId/:selectedBrandname/:selectedBrandName",
    }

}}
function App() {
  return (
    <NavigationContainer
     linking={{linking}}>
      <Stack.Navigator initialRouteName="Main">
                <Stack.Screen name="Main" component={Main} />
                <Stack.Screen name="Brands" component={Brands}/>
                <Stack.Screen name="Products" component={Products}/>
                <Stack.Screen name="ProductDetails" component={ProductDetails}/>
      </Stack.Navigator>
    </NavigationContainer>
  )
}
export default App;
