import {StyleSheet, Text, View,Image,TouchableOpacity,Linking } from 'react-native'
import { LeftOutlined } from '@ant-design/icons'
import React from 'react';
const logoImage='./images/logo.png'
const placeholderImage="images/placeholder.png"
const defaultImage="images/categoriesdefaultimage.png"
function Brands({route,navigation}) {
  const {selectedSymptomId}=route.params
  const [ brands,setBrands ] = React.useState([])
  const loadData=React.useCallback(()=>{
    fetch(`https://www.meijercongestionrelief.com/brands/${selectedSymptomId}`,{
      method: "GET",
      headers: {"Content-Type": "application/json;charset=utf-8"}
    })
    .then((res) => res.json())
    .then((brands)=>{ setBrands(brands) })
    // eslint-disable-next-line
  },[])
  React.useEffect(()=>{
      loadData()
  },[loadData])
  const brandedData=brands.filter((brand)=>{ return brand.priority ===1 || brand.priority ===2 || brand.priority ===3})
  const alavertBrand=brands.filter((brand)=>{return brand.priority === 4 })
  const lodraneBrand=brands.filter((brand)=>{return brand.priority === 11 })
  const loratadineBrand=brands.filter((brand)=>{return brand.priority === 10 })
  const nonBrandedMeijerLoratadine=brands.filter((brand)=>{return brand.priority === 7 && selectedSymptomId ==="1" })
  const nonBrandedStahist1=brands.filter((brand)=>{return brand.priority === 5 && selectedSymptomId ==="1" })
  const nonBrandedData1=brands.filter((brand)=>{ return brand.priority === 9 && selectedSymptomId ==="1"  })
  const sudafedBrand=brands.filter((brand)=>{ return brand.priority === 21 && selectedSymptomId === "2"})
  const mucinexBrand=brands.filter((brand)=>{ return brand.priority === 18 && selectedSymptomId === "2"})
  const aleveAndAdvilBrands=brands.filter((brand)=>{ return ((brand.priority ===14 || brand.priority ===19)  && selectedSymptomId === "2") })
  const meijerMucusNephrexBrands=brands.filter((brand)=>{ return ( (brand.priority ===27  || brand.priority ===28) && selectedSymptomId === "2") })
  const nonBrandedMeijer=brands.filter((brand)=>{ return brand.priority ===6 && selectedSymptomId === "2"})
  const coricidinBrand=brands.filter((brand)=>{ return brand.priority ===17 && selectedSymptomId === "2"})
  const tylenolAndSilfedrineBrands=brands.filter((brand)=>{ return ((brand.priority ===12 || brand.priority === 15)  && selectedSymptomId === "2") })
  //const nonBrandedStahist2=brands.filter((brand)=>{ return brand.priority ===5 && selectedSymptomId === "2"})
  const nonBrandedData2=brands.filter((brand)=>{ return brand.priority >21 && brand.priority !==27 && brand.priority !==24
    && brand.priority !==25  && brand.priority !==28 && selectedSymptomId === "2"})
  const lohistBrand = brands.filter((brand)=>{ return brand.priority === 24})
  const nonBrandedData3=brands.filter((brand)=>{ return brand.priority >=13 && selectedSymptomId === "3"})

  return (
    <View style={styles.mainContainer}>
        <TouchableOpacity onPress={() => navigation.navigate('Main')} underlayColor="#0A4D8C" style={styles.headerArrow}>
                  <Text style={styles.arrowText}><LeftOutlined /></Text>
        </TouchableOpacity>    
        <TouchableOpacity onPress={() => navigation.navigate('Main')} underlayColor="#FFFFFF">
                <View style={styles.logo}><Image source={logoImage} style={styles.headerText}/></View> 
        </TouchableOpacity>
        {  (Object.keys(brands).length === 0 ) ?
        <Text style={styles.loadingText}>Loading...</Text>:
        <View style={styles.content}>
        <Text style={styles.subHeader}>Select a Brand and choose a product to purchase</Text>
            <View style={styles.container}>
                    <View style={styles.container1}>
                      { brandedData && brandedData.map((brand)=>{
                        return  <TouchableOpacity onPress={() => navigation.navigate('Products',
                                                                { selectedSymptomId:selectedSymptomId,
                                                                  selectedBrandId:`${brand.id}`,
                                                                  selectedBrandName:`${brand.name}`})} 
                                                  key={brand.id} 
                                                  underlayColor="#FFFFFF">
                                  <View style={styles.nav1} >
                                      <Image source={brand.picture!==""?brand.picture:placeholderImage} 
                                        style={styles.brandImage}  
                                        defaultSource={defaultImage} />
                                      <Text style={styles.itemName}>{brand.name}</Text>
                                  </View>
                              </TouchableOpacity> }) }      
                      { alavertBrand && alavertBrand.map((brand)=>{
                        return <TouchableOpacity onPress={() => navigation.navigate('Products',
                                                                { selectedSymptomId:selectedSymptomId,
                                                                  selectedBrandId:`${brand.id}`,
                                                                  selectedBrandName:`${brand.name}`})} 
                                                  key={brand.id} 
                                                  underlayColor="#FFFFFF">
                                  <View style={styles.nav1}>
                                      <Image source={brand.picture!==""?brand.picture:placeholderImage} 
                                      style={styles.brandImage} 
                                      defaultSource={defaultImage}/>
                                      <Text style={styles.itemName}>{brand.name}</Text>
                                  </View>
                              </TouchableOpacity> }) }
                      { lodraneBrand && lodraneBrand.map((brand)=>{
                        return <TouchableOpacity onPress={() => navigation.navigate('Products',
                                                                { selectedSymptomId:selectedSymptomId,
                                                                  selectedBrandId:`${brand.id}`,
                                                                  selectedBrandName:`${brand.name}`})} 
                                                  key={brand.id} 
                                                  underlayColor="#FFFFFF">
                                  <View style={styles.nav1}>
                                      <Image source={brand.picture!==""?brand.picture:placeholderImage} 
                                      style={styles.brandImage} 
                                      defaultSource={defaultImage}/>
                                      <Text style={styles.itemName}>{brand.name}</Text>
                                  </View>
                              </TouchableOpacity> }) }
                      { sudafedBrand && sudafedBrand.map((brand)=>{
                            return  <TouchableOpacity onPress={() => navigation.navigate('Products',
                                                                { selectedSymptomId:selectedSymptomId,
                                                                  selectedBrandId:`${brand.id}`,
                                                                  selectedBrandName:`${brand.name}`})} 
                                                  key={brand.id} 
                                                  underlayColor="#FFFFFF">
                                  <View style={styles.nav1} >
                                      <Image source={brand.picture!==""?brand.picture:placeholderImage} 
                                        style={styles.brandImage}  
                                        defaultSource={defaultImage} />
                                      <Text style={styles.itemName}>{brand.name}</Text>
                                  </View>
                              </TouchableOpacity> })} 
                        { mucinexBrand && mucinexBrand.map((brand)=>{
                              return  <TouchableOpacity onPress={() => navigation.navigate('Products',
                                                                { selectedSymptomId:selectedSymptomId,
                                                                  selectedBrandId:`${brand.id}`,
                                                                  selectedBrandName:`${brand.name}`})} 
                                                  key={brand.id} 
                                                  underlayColor="#FFFFFF">
                                  <View style={styles.nav1} >
                                      <Image source={brand.picture!==""?brand.picture:placeholderImage} 
                                        style={styles.brandImage}  
                                        defaultSource={defaultImage} />
                                      <Text style={styles.itemName}>{brand.name}</Text>
                                  </View>
                              </TouchableOpacity> })} 
                        { aleveAndAdvilBrands && aleveAndAdvilBrands.map((brand)=>{
                             return  <TouchableOpacity onPress={() => navigation.navigate('Products',
                                                                { selectedSymptomId:selectedSymptomId,
                                                                  selectedBrandId:`${brand.id}`,
                                                                  selectedBrandName:`${brand.name}`})} 
                                                  key={brand.id} 
                                                  underlayColor="#FFFFFF">
                                  <View style={styles.nav1} >
                                      <Image source={brand.picture!==""?brand.picture:placeholderImage} 
                                        style={styles.brandImage}  
                                        defaultSource={defaultImage} />
                                      <Text style={styles.itemName}>{brand.name}</Text>
                                  </View>
                              </TouchableOpacity> })} 
                        { coricidinBrand && coricidinBrand.map((brand)=>{
                             return  <TouchableOpacity onPress={() => navigation.navigate('Products',
                                                                { selectedSymptomId:selectedSymptomId,
                                                                  selectedBrandId:`${brand.id}`,
                                                                  selectedBrandName:`${brand.name}`})} 
                                                  key={brand.id} 
                                                  underlayColor="#FFFFFF">
                                  <View style={styles.nav1} >
                                      <Image source={brand.picture!==""?brand.picture:placeholderImage} 
                                        style={styles.brandImage}  
                                        defaultSource={defaultImage} />
                                      <Text style={styles.itemName}>{brand.name}</Text>
                                  </View>
                              </TouchableOpacity> })} 
                      { nonBrandedData2 && nonBrandedData2.map((brand)=>{
                        return <TouchableOpacity onPress={() => navigation.navigate('Products',
                                                                { selectedSymptomId:selectedSymptomId,
                                                                  selectedBrandId:`${brand.id}`,
                                                                  selectedBrandName:`${brand.name}`})} 
                                                  key={brand.id} 
                                                  underlayColor="#FFFFFF">
                                  <View style={styles.nav1}>
                                      <Image source={brand.picture!==""?brand.picture:placeholderImage} 
                                      style={styles.brandImage} 
                                      defaultSource={defaultImage}/>
                                      <Text style={styles.itemName}>{brand.name}</Text>
                                  </View>
                              </TouchableOpacity> }) }    
                    </View>
                    <View style={styles.container2}>
                    { nonBrandedMeijerLoratadine && nonBrandedMeijerLoratadine.map((brand)=>{
                        return <TouchableOpacity onPress={() => navigation.navigate('Products',
                                                                { selectedSymptomId:selectedSymptomId,
                                                                  selectedBrandId:`${brand.id}`,
                                                                  selectedBrandName:`${brand.name}`})} 
                                                  key={brand.id} 
                                                  underlayColor="#FFFFFF">
                                  <View style={styles.nav1}>
                                      <Image source={brand.picture!==""?brand.picture:placeholderImage} 
                                      style={styles.brandImage} 
                                      defaultSource={defaultImage}/>
                                      <Text style={styles.itemName}>{brand.name}</Text>
                                  </View>
                              </TouchableOpacity> }) }
                    { loratadineBrand && loratadineBrand.map((brand)=>{
                        return <TouchableOpacity onPress={() => navigation.navigate('Products',
                                                                { selectedSymptomId:selectedSymptomId,
                                                                  selectedBrandId:`${brand.id}`,
                                                                  selectedBrandName:`${brand.name}`})} 
                                                  key={brand.id} 
                                                  underlayColor="#FFFFFF">
                                  <View style={styles.nav1}>
                                      <Image source={brand.picture!==""?brand.picture:placeholderImage} 
                                      style={styles.brandImage} 
                                      defaultSource={defaultImage}/>
                                      <Text style={styles.itemName}>{brand.name}</Text>
                                  </View>
                              </TouchableOpacity> })}
                      { nonBrandedData1 && nonBrandedData1.map((brand)=>{
                        return <TouchableOpacity onPress={() => navigation.navigate('Products',
                                                                { selectedSymptomId:selectedSymptomId,
                                                                  selectedBrandId:`${brand.id}`,
                                                                  selectedBrandName:`${brand.name}`})} 
                                                  key={brand.id} 
                                                  underlayColor="#FFFFFF">
                                  <View style={styles.nav1}>
                                      <Image source={brand.picture!==""?brand.picture:placeholderImage} 
                                      style={styles.brandImage} 
                                      defaultSource={defaultImage}/>
                                      <Text style={styles.itemName}>{brand.name}</Text>
                                  </View>
                              </TouchableOpacity> }) }
                      { nonBrandedStahist1 && nonBrandedStahist1.map((brand)=>{
                        return <TouchableOpacity onPress={() => navigation.navigate('Products',
                                                                { selectedSymptomId:selectedSymptomId,
                                                                  selectedBrandId:`${brand.id}`,
                                                                  selectedBrandName:`${brand.name}`})} 
                                                  key={brand.id} 
                                                  underlayColor="#FFFFFF">
                                  <View style={styles.nav1}>
                                      <Image source={brand.picture!==""?brand.picture:placeholderImage} 
                                      style={styles.brandImage} 
                                      defaultSource={defaultImage}/>
                                      <Text style={styles.itemName}>{brand.name}</Text>
                                  </View>
                              </TouchableOpacity> })}
                        { nonBrandedMeijer && nonBrandedMeijer.map((brand)=>{
                              return  <TouchableOpacity onPress={() => navigation.navigate('Products',
                                                                { selectedSymptomId:selectedSymptomId,
                                                                  selectedBrandId:`${brand.id}`,
                                                                  selectedBrandName:`${brand.name}`})} 
                                                  key={brand.id} 
                                                  underlayColor="#FFFFFF">
                                  <View style={styles.nav1} >
                                      <Image source={brand.picture!==""?brand.picture:placeholderImage} 
                                        style={styles.brandImage}  
                                        defaultSource={defaultImage} />
                                      <Text style={styles.itemName}>{brand.name}</Text>
                                  </View>
                              </TouchableOpacity> })}
                      { meijerMucusNephrexBrands && meijerMucusNephrexBrands.map((brand)=>{
                              return  <TouchableOpacity onPress={() => navigation.navigate('Products',
                                                                { selectedSymptomId:selectedSymptomId,
                                                                  selectedBrandId:`${brand.id}`,
                                                                  selectedBrandName:`${brand.name}`})} 
                                                  key={brand.id} 
                                                  underlayColor="#FFFFFF">
                                  <View style={styles.nav1} >
                                      <Image source={brand.picture!==""?brand.picture:placeholderImage} 
                                        style={styles.brandImage}  
                                        defaultSource={defaultImage} />
                                      <Text style={styles.itemName}>{brand.name}</Text>
                                  </View>
                              </TouchableOpacity> })}
                      
                      { tylenolAndSilfedrineBrands && tylenolAndSilfedrineBrands.map((brand)=>{
                              return  <TouchableOpacity onPress={() => navigation.navigate('Products',
                                                                { selectedSymptomId:selectedSymptomId,
                                                                  selectedBrandId:`${brand.id}`,
                                                                  selectedBrandName:`${brand.name}`})} 
                                                  key={brand.id} 
                                                  underlayColor="#FFFFFF">
                                  <View style={styles.nav1} >
                                      <Image source={brand.picture!==""?brand.picture:placeholderImage} 
                                        style={styles.brandImage}  
                                        defaultSource={defaultImage} />
                                      <Text style={styles.itemName}>{brand.name}</Text>
                                  </View>
                              </TouchableOpacity> })}    
                        { lohistBrand && lohistBrand.map((brand)=>{
                              return  <TouchableOpacity onPress={() => navigation.navigate('Products',
                                                                { selectedSymptomId:selectedSymptomId,
                                                                  selectedBrandId:`${brand.id}`,
                                                                  selectedBrandName:`${brand.name}`})} 
                                                  key={brand.id} 
                                                  underlayColor="#FFFFFF">
                                  <View style={styles.nav1} >
                                      <Image source={brand.picture!==""?brand.picture:placeholderImage} 
                                        style={styles.brandImage}  
                                        defaultSource={defaultImage} />
                                      <Text style={styles.itemName}>{brand.name}</Text>
                                  </View>
                              </TouchableOpacity> })}    
                      
                      { nonBrandedData3 && nonBrandedData3.map((brand)=>{
                        return <TouchableOpacity onPress={() => navigation.navigate('Products',
                                                                { selectedSymptomId:selectedSymptomId,
                                                                  selectedBrandId:`${brand.id}`,
                                                                  selectedBrandName:`${brand.name}`})} 
                                                  key={brand.id} 
                                                  underlayColor="#FFFFFF">
                                  <View style={styles.nav1}>
                                      <Image source={brand.picture!==""?brand.picture:placeholderImage} 
                                      style={styles.brandImage} 
                                      defaultSource={defaultImage}/>
                                      <Text style={styles.itemName}>{brand.name}</Text>
                                  </View>
                              </TouchableOpacity> }) }         
                    </View>
            </View>
            <View style={styles.footer}>
                  <Text style={styles.termsText} onPress={()=>{Linking.openURL("https://www.meijer.com/terms.html")}}>
                      Terms and Conditions of Use
                  </Text>
                  <Text style={styles.privacyText} onPress={()=>{Linking.openURL("https://www.meijer.com/privacy-policy.html")}}>
                      Privacy Policy
                  </Text>
          </View>   
        </View>}
        
    </View>)
}
const styles = StyleSheet.create({
  mainContainer:{
    backgroundColor: "#FFFFFF",
     width:"100%",
     height:800,
     display:"flex",
     flexDirection:"column",
     alignItems:"center"
 },

 headerArrow: {
   backgroundColor:"#0c60a5",
   height:45,
   width:45,
   left:30,
   borderRadius:5,
   top:32,
   position:"absolute"
 },
 arrowText: {
   marginLeft:13,
   marginRight:13,
   marginTop:8,
   color:"#FFFFFF",
   fontSize:22,
 },
 logo: {
   alignItems:"center",
   marginTop:29
 },
 headerText: {
  width:125,
  height:50,
  resizeMode:"contain"
},
loadingText: {
  fontSize:24,
  fontWeight:700,
  lineHeight:30,
  fontStyle:"normal",
  color:"#0c60a5",
  textAlign:'center',
  marginTop: 27,
  width:"100%",
  height:49,
  border:"0.1 solid #0A4D8C"
},
    subHeader: {
        fontSize:24,
        fontWeight:700,
        lineHeight:30,
        fontStyle:"normal",
        color:"#0c60a5",
        textAlign:'center',
        width:"80%",
        height:49,
        border:"0.1 solid #0c60a5"
    },
    content: {
      display:'flex',
      flexDirection:'column',
      marginTop: 27,
      alignItems:"center",
      width:"100%"
    },
    container: {
      display:'flex',
      flexDirection:'row',
      marginTop: 50,
    },
    container1: {
      display:'flex',
      flexDirection:'column',
      marginLeft:15,
    },
    container2: {
      display:'flex',
      flexDirection:'column',
      marginLeft:15,
    },
    nav1: {
        borderRadius: 5,
        marginBottom:25,
        width:160,
        height:150,
        borderWidth:1,
        borderStyle:"solid",
        borderColor:"#C4C4C4",
        alignItems:"center",
    },
    itemName:{
      fontSize: 18,
      fontWeight:700,
      fontStyle:"normal",
      color:"#464852",
      lineHeight:0,
      marginTop:22
    },
    brandImage: {
        height: 80,
        width:120,
        marginTop:22,
        resizeMode:"contain"
    },
    footer: {
      width:"100%",
      height:40,
      marginLeft:0,
      marginTop:82,
      display: "flex",
      flexDirection:"row",
      backgroundColor: "lightgray",
      alignItems:"center"
  },
  termsText: {
      width:"60%",
      height:18,
      fontStyle:"normal",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 22.5,
      color: "#464852",
      marginLeft:20
  },
  privacyText: {
    width:"40%",
    height:18,
    fontStyle:"normal",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 22.5,
    color: "#464852",
    textAlign:"center"
}
  });
  
export default Brands