import { StyleSheet, Text, View,Image,TouchableOpacity,Linking } from 'react-native'
import { LeftOutlined,RightOutlined } from '@ant-design/icons'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import React from 'react'
const logoImage="images/logo.png"
const backgroundImage="images/ProductDetailsBackground.png"
const placeholderImage="images/noImage.png"
const defaultImage="images/productdefaultimage.png"
function ProductDetails({route,navigation}) {
  // eslint-disable-next-line
    const { selectedSymptomId,selectedBrandId,selectedBrandName,selectedProductId }= route.params
    const [ imageChange,setImageChange ] = React.useState(false)
    const [ medication,setMedication ] = React.useState({})
    const [ changeDot1Style,setChangeDot1Style ] = React.useState(true)
    const [ changeDot2Style,setChangeDot2Style ] = React.useState(false)
    const [disableLeft,setDisableLeft] = React.useState(true)
    const [disableRight,setDisableRight] = React.useState(false)
  const loadData=React.useCallback(()=>{
    fetch(`https://www.meijercongestionrelief.com/medication/${selectedProductId}`,{
      method: "GET",
      headers: {"Content-Type": "application/json;charset=utf-8"}
    })
    .then((res) => res.json())
    .then((medication)=>setMedication(medication))
    // eslint-disable-next-line
  },[])
  React.useEffect(()=>{
    loadData() 
  },[loadData])
    function frontImage(resetTransform){
      setDisableRight(!disableRight)
      setDisableLeft(!disableLeft)
      setImageChange(false)
      resetTransform()
      if(changeDot1Style){
      setChangeDot1Style(changeDot1Style)
      setChangeDot2Style(!changeDot1Style)
      }
      else
      {
      setChangeDot1Style(!changeDot1Style)
      setChangeDot2Style(changeDot1Style)
      }
    }
    function backImage(resetTransform){
      setDisableRight(!disableRight)
      setDisableLeft(!disableLeft)
      setImageChange(true)
      resetTransform()
      if(changeDot2Style){
      setChangeDot2Style(changeDot2Style)
      setChangeDot1Style(!changeDot2Style)
      }
      else{
      setChangeDot2Style(!changeDot2Style)
      setChangeDot1Style(changeDot2Style)
      }
    }
    return (
    <View style={styles.mainContainer}>
       <TouchableOpacity onPress={()=>navigation.navigate('Products',
                                      { selectedSymptomId:selectedSymptomId,
                                        selectedBrandId:selectedBrandId,
                                        selectedBrandName:selectedBrandName})}
                         underlayColor="#0A4D8C" 
                         style={styles.headerArrow}>
                  <Text style={styles.arrowText}><LeftOutlined /></Text>
        </TouchableOpacity> 
        <TouchableOpacity onPress={()=>navigation.navigate('Main')} underlayColor="#FFFFFF">
                <View style={styles.logo}><Image source={logoImage} style={styles.headerText}/></View>
        </TouchableOpacity> 
        <View style={styles.subHeaderContainer}>
        {selectedBrandId === "1" ? <Text style={styles.subHeader}>Powerful allergy congestion relief</Text> : <Text style={styles.subHeader}></Text>  }
        </View>
            <TransformWrapper initialScale={1}>
            {({ resetTransform }) => (
        <View style={styles.container}>
            <View style={styles.subContainer}>
                      
                            <TransformComponent>
                              <Image source={imageChange?(medication.backPicture!==""?medication.backPicture:placeholderImage):(medication.frontPicture!==""?medication.frontPicture:placeholderImage) } 
                               style={styles.brandImage} 
                               defaultSource={defaultImage} />
                            </TransformComponent>     
            </View>
            <View style={styles.vectorImageContainer}>
            <TouchableOpacity style={changeDot1Style?styles.dot1:styles.dot3} onPress={() =>{frontImage(resetTransform)}} disabled={disableLeft}><Text style={styles.dotArrowText}><LeftOutlined /></Text></TouchableOpacity>
            <TouchableOpacity style={changeDot2Style?styles.dot2:styles.dot4} onPress={() =>{backImage(resetTransform)}} disabled={disableRight}><Text style={styles.dotArrowText}><RightOutlined /></Text></TouchableOpacity>
            </View>
            <Text style={styles.messageText}>Take this digital card to the pharmacy for purchase</Text>
            <Text style={styles.upcCodeText}>UPC CODE</Text>
                <Text style={styles.upcCode} >
                            {medication.upcCode}
                </Text>
                <View style={styles.footer}>
                  <Text style={styles.termsText} onPress={()=>{Linking.openURL("https://www.meijer.com/terms.html")}}>
                      Terms and Conditions of Use
                  </Text>
                  <Text style={styles.privacyText} onPress={()=>{Linking.openURL("https://www.meijer.com/privacy-policy.html")}}>
                      Privacy Policy
                  </Text>
                </View>  
        </View>  
         )}
        </TransformWrapper> 
        
    </View>)
}
const styles = StyleSheet.create({
  mainContainer:{
    backgroundColor: "#FFFFFF",
     width:"100%",
     height:800,
     display:"flex",
     flexDirection:"column",
     alignItems:"center"
 },
 headerArrow: {
  backgroundColor:"#0c60a5",
  height:45,
  width:45,
  left:30,
  borderRadius:5,
  top:32,
  position:"absolute"
},
arrowText: {
  marginLeft:13,
  marginRight:13,
  marginTop:8,
  color:"#FFFFFF",
  fontSize:22,
},
logo: {
  alignItems:"center",
  marginTop:29,
},
headerText: {
 width:125,
 height:50,
 resizeMode:"contain"
},
    subHeaderContainer: {
       display:'flex',
       flexDirection:"row",
       justifyContent:"center"
    },
    subHeader: {
        fontSize:27,
        fontWeight:650,
        lineHeight:30,
        fontStyle:"normal",
        color:"#0c60a5",
        marginTop:26,
        textAlign:'center',
        width:"90%",
        height:51,
        marginLeft:"30",
        border:"0.1 solid #0c60a5",
    },
    container: {
      display:'flex',
      flexDirection:'column',
      marginTop: 10,
      width:"100%",
      height:642,
      alignItems:"center",
      backgroundImage: `url(${backgroundImage})`,
      backgroundBlendMode: "luminosity",
      backgroundColor:"#FFFFFF"
    },
    subContainer: {
        display:'flex',
        flexDirection:'column',
        width:"100%",
        height:345,
        alignItems:"center",
        backgroundImage:"linear-gradient(180deg, #FFFFFF 23.84%, rgba(255, 255, 255, 0) 100%)"
    },
    brandImage: {
        width:357,
        height:254,
        marginTop:49,
        resizeMode:"contain",
    },
    vectorImageContainer: {
      display:"flex",
      flexDirection:"row",
      marginTop:3,
      width:100,
      justifyContent:'space-between'
    },
    
    dot1: {
      textAlign:"center",
      width:36,
      height:36,
      borderRadius:5,
      backgroundColor:"#0c60a5",
      color:"#FFFFFF",
      border: "1px solid #C4C4C4",
      opacity:0.3
    },
    dot3: {
      textAlign:"center",
      width:36,
      height:36,
      borderRadius:5,
      backgroundColor:"#0c60a5",
      color:"#FFFFFF",
      border: "1px solid #C4C4C4",
      
    },
    dot2: {
      textAlign:"center",
      width:36,
      height:36,
      borderRadius:5,
      backgroundColor:"#0c60a5",
      color:"#FFFFFF",
      border: "1px solid #C4C4C4",
      marginLeft:30,
      opacity:0.3
    },
    dot4: {
      textAlign:"center",
      width:36,
      height:36,
      borderRadius:5,
      backgroundColor:"#0c60a5",
      color:"#FFFFFF",
      border: "1px solid #C4C4C4",
      marginLeft:30
    },
    dotArrowText: {
      color:"#FFFFFF",
      marginTop:3,
      fontSize:22,
    },
    messageText: {
        width:"90%",
        height:60,
        fontStyle:"normal",
        fontWeight: 700,
        fontSize: 23,
        lineHeight: 30,
        textAlign:"center",
        color:"#DB232D",
        marginTop:25
    },
    upcCodeText: {
        width:"100%",
        height:18,
        fontStyle:"normal",
        fontWeight: 400,
        fontSize: 18,
        lineHeight: 22.5,
        textAlign:"center",
        color: "#464852",
        marginTop:25
    },
    upcCode: {
        width:"100%",
        height:23,
        fontStyle:"normal",
        fontWeight: 600,
        fontSize: 18,
        lineHeight: 44,
        textAlign:"center",
        color: "#0c60a5",
    },
    footer: {
        width:"100%",
        height:40,
        marginLeft:0,
        marginTop:82,
        display: "flex",
        flexDirection:"row",
        backgroundColor: "lightgray",
        alignItems:"center"
    },
    termsText: {
        width:"60%",
        height:18,
        fontStyle:"normal",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: 22.5,
        color: "#464852",
        marginLeft:20
    },
    privacyText: {
      width:"40%",
      height:18,
      fontStyle:"normal",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 22.5,
      color: "#464852",
      textAlign:"center"
  }
  });
  
export default ProductDetails